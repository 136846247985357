@import "/src/styles/variables.scss";
@import "/src/styles/breakpoints.scss";

.footer {
  background-color: $color-black;
  color: $color-primary;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 16px;
  padding: 30px $side-padding 20px $side-padding;
  @include medium {
    padding: 40px 30px 20px 30px;
  }
}

.logoSection {
  flex: 1;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  @include medium {
    flex: 1;
  }
}

.socialsSection {
  flex: 1;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  .socials {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20;
    a {
      margin: 0 10px;
      transition: color 0.3s ease;
      &:hover {
        color: $color-white;
      }
    }
  }
}

.termsSection {
  text-align: end;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-items: flex-end;
  // p {
  //   color: $color-primary;
  //   &:hover {
  //     color: $color-white;
  //   }
  // }
  color: $color-primary;
  .allergens {
    font: normal normal bold 14px/19px $font-family-gibson;
    letter-spacing: 0.28px;
    text-transform: uppercase;
    margin-bottom: 18px;
  }
  .legalLinks {
    display: flex;
    gap: 4px;
    flex-direction: column;
  }
  .copyright {
    flex: 1;
    color: $color-white;
    opacity: 0.5;
    text-align: right;
    margin-top: 30px;
  }
  p,
  a {
    font-size: 12px;
  }
}
