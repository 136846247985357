@import "/src/styles/breakpoints.scss";
@import "/src/styles/variables.scss";

.stickyHeader {
  position: fixed;
  top: 0;
  z-index: 1000;
  width: 100%;
  height: var(--appbar-height);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 $side-padding;
  transition: background-color 0.3s, opacity 0.3s;
  @include medium {
    padding: 0 24px;
  }
}

.orderNow {
  flex: 1;
}

.logo {
  flex: 1;
  text-align: center;
  display: inline-block;
  height: var(--appbar-height);
  position: relative;
  padding: 14px 0;
}
