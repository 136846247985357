@import "/src/styles/variables.scss";

.headerBackground {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: $color-black;
  z-index: -1;
}

.opaque {
  opacity: 0.8;
}
