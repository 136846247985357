@import "/src/styles/breakpoints.scss";
@import "/src/styles/variables.scss";

$header-height: 54px;
$group-item-height: 54px;
$location-item-height: 44px;

$max-height: 80vh;
$speed: 0.3s;

$panel-width: 280px;

$contents-max-height: calc($max-height - $header-height);

:export {
  headerHeight: $header-height;
  groupItemHeight: $group-item-height;
  locationItemHeight: $location-item-height;
}

.megaMenu {
  pointer-events: none;
  display: none;
  position: absolute;
  top: -4px;
  left: -4px;
  background-color: $color-secondary;
  color: $color-white;
  height: 0;
  max-height: $max-height;
  width: 128px;
  border-radius: 8px;
  box-shadow: $color-black 5px 5px;
  opacity: 0;
  transition: height $speed ease, opacity $speed ease, width $speed ease;
  z-index: $z-index-modal;
  @include medium {
    display: block;
    overflow: hidden;
  }

  &.open {
    pointer-events: all;
    width: $panel-width;
    opacity: 1;
  }
  &.groupOpen {
    width: calc($panel-width * 2);
  }
}

.menuToggle {
  height: $header-height;
  color: $color-white;
  padding: 0 20px;
  width: 100%;
  text-align: left;
  font-size: 14px;
  font-family: $font-family-gibson;
  font-weight: $font-weight-gibson-bold;
  text-transform: uppercase;
  border-bottom: 2px solid rgba($color-neutral-1, 0.5);
  background: $color-secondary;
}

.groupItem {
  height: $group-item-height;
  color: $color-primary;
  padding: 0 20px;
  width: 100%;
  text-align: left;
  font-size: 14px;
  font-family: $font-family-gibson;
  font-weight: $font-weight-gibson-bold;
  text-transform: uppercase;
  border-bottom: 2px solid rgba($color-neutral-1, 0.5);
  background-color: $color-secondary;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  transition: color $speed ease, background-color $speed ease;
}
.groupItemSelected {
  color: $color-secondary;
  background-color: $color-white;
  transform: color $speed ease;
}

.groupItemIcon {
  height: 8px;
  width: 8px;
  transform: rotate(-90deg);
}

.menuContent {
  display: flex;
}

.groups {
  flex: 1;
  overflow: auto;
  max-height: $contents-max-height;
}

.locations {
  width: 0;
  opacity: 0;
  transition: height $speed ease, opacity $speed ease, width $speed ease;
  max-height: $contents-max-height;
  overflow-y: auto;
  overflow-x: hidden;
  background-color: $color-white;
  &.locationsOpen {
    width: 280px;
    opacity: 1;
    border-left: 2px solid rgba($color-neutral-1, 0.5);
  }
  li {
    list-style: none;
    height: $location-item-height;
    width: 100%;
    text-align: left;
    font: normal normal 600 12px/19px $font-family-gibson;
    border-bottom: 2px solid rgba($color-neutral-1, 0.5);
    background-color: $color-white;
    color: $color-secondary;
    text-transform: capitalize;
    a {
      display: flex;
      align-items: center;
      padding: 0 16px;
      height: 100%;
      width: 100%;
      transition: transform $speed ease;
      &:hover {
        transform: translateX(3px);
      }
    }
  }
}
